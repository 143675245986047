<template>
    <div class="page-content">
        <div class="grid">
            <div class="row no-gutters page-list-header">
                <div class="col l-6 m-6 c-12">
                    <h4 class="page-list-title"><md-icon>note_add</md-icon> <span>{{title}}</span></h4>
                </div>
                <div class="col l-6 m-6 c-12">
                    <div class="page-list-action"> 
                        <md-checkbox v-model="saveAndCreate" class="md-primary">Lưu và tạo mới</md-checkbox>
                        <md-button v-shortkey="['ctrl', 'l']" @shortkey="submit()" @click="submit()" class="md-raised md-primary"><span>L</span>ưu<md-tooltip>Lưu (Ctrl + L)</md-tooltip></md-button>
                        <md-button v-shortkey="['ctrl', 'i']" @shortkey="back()" @click="back()" class="md-raised">Quay lạ<span>i</span><md-tooltip>Quay lại (Ctrl + Q)</md-tooltip></md-button>
                    </div>
                </div>
            </div>
            <div class="page-list-body">
                <div class="form-body">
                    <div class="group-box">
                        <div class="group-title">Thông tin khách hàng</div>
                        <div class="row">
                            <div class="col l-4 m-4 c-12">
                                <div class="row">
                                    <div class="col l-6 m-6 c-6">
                                        <md-field :class="{'md-invalid': submitted && $v.entity.companyCode.$error }">
                                            <label for="companyCode">Mã khách hàng</label>
                                            <md-input name="companyCode" v-model="entity.companyCode"></md-input>
                                            <span class="md-error" v-if="submitted && !$v.entity.companyCode.required">Vui lòng nhập mã khách hàng</span>
                                        </md-field>
                                    </div>
                                    <div class="col l-6 m-6 c-6">
                                        <div class="form-control">
                                            <md-autocomplete v-model="categoryName" @md-selected="getCategorySelected" :md-options="categories" @md-changed="getCategories"  @md-opened="getCategories" :class="{'md-invalid': submitted && $v.entity.categoryId.$error }">
                                                <label>Nhóm khách hàng</label>
                                                <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                                    <md-highlight-text :md-term="term">{{ item.categoryName }}</md-highlight-text>
                                                </template>
                                                <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                                    "{{ term }}" Không tìm thấy!
                                                </template>
                                                <span class="md-error" v-if="submitted && !$v.entity.categoryId.required">Vui lòng chọn nhóm khách hàng</span>
                                            </md-autocomplete>
                                            <md-button @click="openCategory()" class="md-icon-button">
                                                <md-icon>manage_search</md-icon>
                                            </md-button>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col l-6 m-6 c-12">
                                        <md-field :class="{'md-invalid': submitted && $v.entity.companyAlias.$error }">
                                            <label for="companyAlias">Tên viết tắt</label>
                                            <md-input name="companyAlias" v-model="entity.companyAlias"></md-input>
                                            <span class="md-error" v-if="submitted && !$v.entity.companyAlias.required">Vui lòng nhập tên viết tắt</span>
                                        </md-field>
                                    </div>
                                    <div class="col l-6 m-6 c-12">
                                        <md-field :class="{'md-invalid': submitted && $v.entity.companyName.$error }">
                                            <label for="companyName">Tên khách hàng</label>
                                            <md-input name="companyName" v-model="entity.companyName"></md-input>
                                            <span class="md-error" v-if="submitted && !$v.entity.companyName.required">Vui lòng nhập tên khách hàng</span>
                                        </md-field>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col l-6 m-6 c-12">
                                        <div class="form-control">
                                            <md-autocomplete v-model="typeName" @md-selected="getTypeSelected" :md-options="types" @md-changed="getTypes"  @md-opened="getTypes" :class="{'md-invalid': submitted && $v.entity.typeCode.$error }">
                                                <label>Loại khách hàng</label>
                                                <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                                    <md-highlight-text :md-term="term">{{ item.name }}</md-highlight-text>
                                                </template>
                                                <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                                    "{{ term }}" Không tìm thấy!
                                                </template>
                                                <span class="md-error" v-if="submitted && !$v.entity.typeCode.required">Vui lòng chọn loại khách hàng</span>
                                            </md-autocomplete>
                                            <md-button @click="openType()" class="md-icon-button">
                                                <md-icon>manage_search</md-icon>
                                            </md-button>
                                        </div>
                                    </div>
                                    <div class="col l-6 m-6 c-12">
                                        <md-field>
                                            <label for="phone">Mã số thuế</label>
                                            <md-input name="phone" v-model="entity.taxCode" ></md-input>
                                        </md-field>
                                    </div>
                                </div>
                                <md-field>
                                    <label for="address">Địa chỉ</label>
                                    <md-input name="address" v-model="entity.address" ></md-input>
                                </md-field>
                            </div>
                            <div class="col l-4 m-4 c-12">
                                <div class="form-control">
                                    <md-autocomplete v-model="staffName" @md-selected="getStaffSelected" :md-options="staffs" @md-changed="getStaffs"  @md-opened="getStaffs" :class="{'md-invalid': submitted && $v.entity.staffId.$error }">
                                        <label>Nhân viên phụ trách</label>
                                        <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                            <md-highlight-text :md-term="term">{{ item.fullName }}</md-highlight-text>
                                        </template>
                                        <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                            "{{ term }}" Không tìm thấy!
                                        </template>
                                        <span class="md-error" v-if="submitted && !$v.entity.staffId.isSelected">Vui lòng chọn nhân viên phụ trách</span>
                                    </md-autocomplete>
                                    <md-button @click="openStaff()" class="md-icon-button">
                                        <md-icon>manage_search</md-icon>
                                        <md-tooltip>Tìm nhân viên</md-tooltip>
                                    </md-button>
                                </div>
                                <div class="row">
                                    <div class="col l-6 m-6 c-12">
                                        <md-field>
                                            <label for="phone">Số điện thoại</label>
                                            <md-input name="phone" v-model="entity.phone" ></md-input>
                                        </md-field>
                                    </div>
                                    <div class="col l-6 m-6 c-12">
                                        <md-field>
                                            <label for="fax">Fax</label>
                                            <md-input name="fax" v-model="entity.fax" ></md-input>
                                        </md-field>
                                    </div>
                                </div>
                                <md-field>
                                    <label for="email">Email</label>
                                    <md-input name="email" v-model="entity.email" ></md-input>
                                </md-field>
                                <md-field>
                                    <label for="note">Ghi chú</label>
                                    <md-input name="note" v-model="entity.note" ></md-input>
                                </md-field>
                            </div>
                            <div class="col l-4 m-4 c-12">
                                <div class="row">
                                    <div class="col l-6 m-6 c-12">
                                        <div class="form-control">
                                            <md-autocomplete v-model="bankName" @md-selected="getBankSelected" :md-options="banks" @md-changed="getBanks"  @md-opened="getBanks">
                                                <label>Ngân hàng</label>
                                                <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                                    <md-highlight-text :md-term="term">{{ item.companyName }}</md-highlight-text>
                                                </template>
                                                <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                                    "{{ term }}" Không tìm thấy!
                                                </template>
                                            </md-autocomplete>
                                            <md-button @click="openBank()" class="md-icon-button">
                                                <md-icon>manage_search</md-icon>
                                            </md-button>
                                        </div>
                                    </div>
                                    <div class="col l-6 m-6 c-12">
                                        <md-field>
                                            <label for="bankNumber">Số TK</label>
                                            <md-input name="bankNumber" v-model="entity.bankNumber" ></md-input>
                                        </md-field>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col l-6 m-6 c-12">
                                        <md-field>
                                            <label for="bankBranch">Chi nhánh</label>
                                            <md-input name="bankBranch" v-model="entity.bankBranch" ></md-input>
                                        </md-field>
                                    </div>
                                    <div class="col l-6 m-6 c-12">
                                        <md-field>
                                            <label for="email">Nợ ban đầu</label>
                                            <md-input name="email" v-model="entity.debt" ></md-input>
                                        </md-field>
                                    </div>
                                </div>
                                <div class="radio-group">
                                    <label>Chủ xe</label>
                                    <div class="form-control">
                                        <md-radio v-model="entity.ownerVehicle" :value="true">Là chủ xe</md-radio>
                                        <md-radio v-model="entity.ownerVehicle" :value="false">Không</md-radio>
                                    </div>
                                </div>
                                <div class="radio-group">
                                    <label>Trạng thái</label>
                                    <div class="form-control">
                                        <md-radio v-model="entity.isActive" :value="true">Hoạt động</md-radio>
                                        <md-radio v-model="entity.isActive" :value="false">Khóa</md-radio>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="group-box">
                        <div class="group-title">Thông tin liên hệ</div>
                        <div class="row">
                            <div class="col l-3 m-3 c-12">
                                <md-field>
                                    <label for="delegateName">Người liên hệ</label>
                                    <md-input name="delegateName" v-model="entity.delegateName" ></md-input>
                                </md-field>
                            </div>
                            <div class="col l-3 m-3 c-12">
                                <md-field>
                                    <label for="delegatePosition">Chức vụ</label>
                                    <md-input name="delegatePosition" v-model="entity.delegatePosition" ></md-input>
                                </md-field>
                            </div>
                            <div class="col l-3 m-3 c-12">
                                <md-field>
                                    <label for="delegatePhone">Số điện thoại</label>
                                    <md-input name="delegatePhone" v-model="entity.delegatePhone" ></md-input>
                                </md-field>
                            </div>
                            <div class="col l-3 m-3 c-12">
                                <md-field>
                                    <label for="delegateEmail">Email</label>
                                    <md-input name="delegateEmail" v-model="entity.delegateEmail" ></md-input>
                                </md-field>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-body form-tab">
                    <div class="row">
                        <div class="col l-12 m-12 c-12">
                            <div class="tool-bar">
                                <md-button @click="addRow()" class="md-primary"><md-icon>add_card</md-icon> Thêm dòng<md-tooltip>Thêm dòng</md-tooltip></md-button>
                            </div>
                            <table class="data-table-2"> 
                                <thead> 
                                    <tr> 
                                        <th style="width:100px;">#</th> 
                                        <th class="left" style="width:28%;">Mã kho hàng</th> 
                                        <th class="left" style="width:30%;">Tên viết tắt</th> 
                                        <th class="left" style="width:30%;">Tên kho hàng</th> 
                                        <th style="width:120px">Hành động</th> 
                                    </tr> 
                                </thead> 
                                <tbody v-if="loadding == true" style="height: 100px;"> 
                                    <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                </tbody>
                                <tbody v-if="loadding == false"> 
                                    <tr v-for="(item, index) in entity.storeList" :key="item.id" :class="{ 'odd': index % 2 !== 0 }"> 
                                        <td class="center">{{index + 1}}</td> 
                                        <td class="left">
                                            <div class="grid-control">
                                                <input v-model="item.locationCode" class="form-control" type="text">
                                                <md-button @click="openLocation(item.id)" class="md-icon-button">
                                                    <md-icon>manage_search</md-icon>
                                                    <md-tooltip>Tìm kho hàng</md-tooltip>
                                                </md-button>
                                            </div>
                                        </td> 
                                        <td class="left">{{item.locationAlias}}</td> 
                                        <td class="left">{{item.locationName}}</td> 
                                        <td class="grid-action">
                                            <a @click="delRow(item)"><md-icon>clear</md-icon><md-tooltip>Xóa dòng</md-tooltip></a>
                                        </td> 
                                    </tr> 
                                </tbody> 
                            </table> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <staffList ref="staffList" @close="closeStaff"/>
        <typeList ref="typeList" :group="group" title="Loại khách hàng" @close="closeType"/>
        <categoryList ref="categoryList" :type="1" title="nhóm khách hàng" @close="closeCategory"/>
        <bankList ref="bankList" title="ngân hàng" :type="companyType" @close="closeBank"/>
        <locationList ref="locationList" @close="closeLocation"/>
   </div>
</template>
<script>
    import companyService from '../../../api/companyService';
    import categoryService from '../../../api/categoryService';
    import typeService from '../../../api/typeService';
    import staffService from '../../../api/staffService';
    import messageBox from '../../../utils/messageBox';
    import { required } from 'vuelidate/lib/validators';
    import { mapActions } from 'vuex';
    import common from '../../../mixins';
    import typeList from '../../../components/popup/_TypeList.vue';
    import categoryList from '../../../components/popup/_CategoryList.vue';
    import bankList from '../../../components/popup/_CompanyList.vue';
    import staffList from '../../../components/popup/_StaffList.vue';
    import locationList from '../../../components/popup/_LocationList.vue';

    export default ({
        components: {
            typeList,
            categoryList,
            bankList,
            staffList,
            locationList
        },
        metaInfo: {
            title: 'Thêm/Cập nhật khách hàng'
        },
        data() {
            return {
                title: '',
                id: 0,
                submitted: false,
                loadding: false,
                group: common.groupCode.customer,
                companyType: common.companyType.bank,
                entity: { id: 0, storeList: [], delegateName: '', delegatePosition: '', delegatePhone: '', delegateEmail: '', categoryId: 0, staffId: 0, taxCode: '', debt: 0, bankId: null, bankNumber: '', bankBranch: '', ownerVehicle: false, companyCode: '', companyAlias: '', companyName: '', phone: '', fax: '', address: '', email: '', note: '', typeCode: '', companyType: common.companyType.customer, isActive: true },
                typeName: '',
                types: [],
                categoryName: '',
                categories: [],
                bankName: '',
                banks: [],
                staffName: '',
                staffs: [],
                saveAndCreate: false,
                locationName: '',
                locations: []
            }
        },
        created(){
            this.id = this.$route.params.id;
            if(this.id > 0){
               this.title = 'Cập nhật khách hàng';
               this.getById();
            }
            else{
               this.title = 'Thêm mới khách hàng';
            }
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            //Add Row
            addRow(){
                let item = { id: 'id_' + common.getFakeId(), locationCode: '', locationAlias: '', locationName: '' };
                this.entity.storeList.push(item);
            },

            delRow(id){
                const index = this.entity.storeList.findIndex(x => x.id == id);
                this.entity.storeList.splice(index, 1);
            },

            //Location
            closeLocation(item){
                const selected = this.entity.storeList.findIndex(x => x.id == this.selectedId);
                this.entity.storeList[selected].id = item.id;
                this.entity.storeList[selected].locationCode = item.locationCode;
                this.entity.storeList[selected].locationAlias = item.locationAlias;
                this.entity.storeList[selected].locationName = item.locationName;
                this.$refs.locationList.close();
                this.selectedId = '';
            },

            openLocation(id){
                this.selectedId = id;
                this.$refs.locationList.open(0);
            },

            //Sales
            closeStaff(item){
                this.staffName = item.fullName;
                this.entity.staffId = item.id;
                this.$refs.staffList.close();
            },

            getStaffSelected(val){
                this.entity.staffId = val.id;
                this.staffName = val.fullName;
            },

            openStaff(){
                this.$refs.staffList.open();
            },

            getStaffs(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, name:  searchTerm };
                staffService.getStaffs(search).then((response) => {
                    if(response.statusCode == 200){
                        this.staffs = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            //bank
            closeBank(item){
                this.bankName = item.companyName;
                this.entity.bankId = item.id;
                this.$refs.bankList.close();
            },

            getBankSelected(val){
                this.entity.bankId = val.id;
                this.bankName = val.companyName;
            },

            openBank(){
                this.$refs.bankList.open(1);
            },

            getBanks(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, companyType: common.companyType.bank, code: searchTerm };
                companyService.getCompanies(search).then((response) => {
                    if(response.statusCode == 200){
                        this.banks = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            //Category
            closeCategory(item){
                this.categoryName = item.categoryName;
                this.entity.categoryId = item.id;
                this.$refs.categoryList.close();
            },

            getCategorySelected(val){
                this.entity.categoryId = val.id;
                this.categoryName = val.categoryName;
            },

            openCategory(){
                this.$refs.categoryList.open();
            },

            getCategories(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, type: 1, code: searchTerm };
                categoryService.getCategories(search).then((response) => {
                    if(response.statusCode == 200){
                        this.categories = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            //Type
            closeType(item){
                this.typeName = item.name;
                this.entity.typeCode = item.code;
                this.$refs.typeList.close();
            },

            getTypeSelected(val){
                this.entity.typeCode = val.code;
                this.typeName = val.name;
            },

            openType(){
                this.$refs.typeList.open();
            },

            getTypes(searchTerm){
                typeService.getTypes(this.group, searchTerm).then((response) => {
                    if(response.statusCode == 200){
                        this.types = response.data;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            submit(){
                this.submitted = true;
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                if(this.entity.storeList.length > 0){
                    this.entity.storeList = this.entity.storeList.filter(item => item.id > 0);
                }
                if(this.id > 0){
                    this.edit();
                }
                else{
                    this.add();
                }
            },

            add(){
                this.setLoading(true);
                companyService.add(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Thêm mới thành công");
                        if(!this.saveAndCreate){
                            this.$router.push('/customer');
                        }
                        else{
                            this.submitted = false;
                            this.entity = { id: 0, delegateName: '', delegatePosition: '', delegatePhone: '', delegateEmail: '', categoryId: 0, staffId: 0, taxCode: '', debt: 0, bankId: null, bankNumber: '', bankBranch: '', ownerVehicle: false, companyCode: '', companyAlias: '', companyName: '', phone: '', fax: '', address: '', email: '', note: '', typeCode: '', companyType: common.companyType.customer, isActive: true };
                        }
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                 }).finally(() => { this.setLoading(false); });
            },

            edit(){
                this.setLoading(true);
                companyService.edit(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Cập nhật thành công");
                        this.$router.push('/customer');
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            getById(){
                this.setLoading(true);
                companyService.getById(this.id).then((response) => {
                    if(response.statusCode == 200){
                        this.entity = response.data;
                        this.typeName = response.data.type.name;
                        this.categoryName = response.data.category.categoryName;
                        this.staffName = response.data.staff.fullName;
                        if(response.data.bankId > 0){
                            this.bankName = response.data.bank.companyName;
                        }
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },
            back(){
                this.$router.push('/customer');
            }
        },
        watch: { 
            typeName: function (val) { 
                if(val == ''){
                    this.entity.typeCode = '';
                }
            },
            categoryName: function (val) { 
                if(val == ''){
                    this.entity.categoryId = 0;
                }
            },
            bankName: function (val) { 
                if(val == ''){
                    this.entity.bankId = null;
                }
            }
        },
        validations: {
            entity: {
                categoryId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                staffId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                companyCode: { required },
                typeCode: { required },
                companyAlias: { required },
                companyName: { required }
            }
       }
     })

</script>
